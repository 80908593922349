<template>
    <div class="teaching-research-course-schedule-temp">
        <div v-if="!errorShow" style="display: flex">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow" class="content-main">
               <filter-wrapper
                    :queryList="queryList"
                    :statusList="statusList"
                    :schedulingClassesList="schedulingClassesList"
                    :teacherList="teacherList"
                    :gradeList="gradeList"
                    :formData="formData"
                    @handleChangeCourseType="handleChangeCourseType"
                    @changeCascader="changeCascader"
                    @handlerScheduleClassIdChange="handlerScheduleClassIdChange"
                    @handlerTeacherChange="handlerTeacherChange"
                    @handlerGradeChange="handlerGradeChange"
               >
               </filter-wrapper>
               <course-schedule-view
                    v-if="queryList.classTeacherId || queryList.scheduleGradeId"
                    v-loading="getDataLoading"
                    :advanceTime="advanceTime"
                    :getDataLoading="getDataLoading"
                    :classAndTeacher="classAndTeacher"
                    :queryList="queryList"
                    :courseList="courseList"
                    :weekList="weekList"
                    :isTourClass="isTourClass"
                    :type="queryList.courseType"
                    :selectedKeyArr="keyArr"
                    :showDownloadPDFType="showDownloadPDFType"
                    @dataAddCourse="dataAddCourse"
                    @changeCheck="changeCheck"
               >
               </course-schedule-view>
               <div v-else class="empty-area">
                <div>
                    <img :src="emptyImg" alt="">
                    <span>请选择查询条件</span>
                </div>
               </div>
            </div>
            <div class="parting-line"></div>
            <div class="select-wrap">
                <div class="title">
                    <div class="line"></div>
                    <span class="text">巡课对象</span>
                </div>
                <div class="statistics">
                    <span>已选：{{ checkedLength }}</span>
                    <span class="clear" @click="delAll()">清空</span>
                </div>
                <div class="course-taget">
                    <TimeTable v-for="(item, index) in selectList" :key="index" :data="item" @delItem="delItem" >
                    </TimeTable>
                </div>
            </div>
        </div>
        <error v-else :errorShow="errorShow"></error>
        <div v-if="!errorShow" class="btn" style="text-align: right;padding-right:11px;margin-top:5px">
            <el-button @click="handleCancel(false)">取消</el-button>
            <el-button type="primary" @click="handleCancel(true)">确定</el-button>
        </div>
    </div>
</template>
<script>
import {
    // 功能组件
    Loading,
    Error,
    DialogWrapper,
} from 'common-local';
import {formatTreeData} from "@/libs/utils";
import FilterWrapper from './FilterData.vue';
import CourseScheduleView from './CourseSchedule.vue';
import TimeTable from './TimeTable.vue';

import ExpandFilter from "@/components/scrollWrapper/Sub/ExpandFilter.vue";

import { mapState } from 'vuex';

import { CourseScheduleTemp } from '@/operations/teachingResearchCourseScheduleTemp.js'
import { TeachingResearchCourseScheduleTempModel } from '@/models/TeachingResearchCourseScheduleTemp.js';

export default {
    name: "TeachingResearchCourseScheduleTempScrollWrapper",
    components: {
        Loading,
        Error,
        DialogWrapper,
        FilterWrapper,
        CourseScheduleView,
        ExpandFilter,
        TimeTable
    },
    props: {
        selectedCourseList: Array,
    },
    data () {
        return {
            keyArr:[],
            checkedLength:0,//已选课程的数量
            selectList: [],
            loding:true,//切换课表类型
            errorShow: false,
            loadingShow: false,
            getDataLoading: false,
            queryList: {
                schoolId: '',
                productId: '',
                dayWeek: 'week',
                isTourClass: '',
                startTime: '',
                endTime: '',
                selectDay: '',
                classTeacherId: '',
                courseType: '2',
                scheduleClassId: '',
                scheduleGradeId: '' // 年级
            },
            statusList: [
                {
                    id: 'day',
                    label: '日'
                },
                {
                    id: 'week',
                    label: '周'
                }
            ],
            PickerDate:{
                type: 'datePick',
                label: '',
                startPlaceholder: '开始日期',
                endPlaceholder: '结束日期',
                key: 'endTime',
                value: '',
            },
            /**
             * 筛选数据
             * schedulingClassesList 班级
             * schedulingClassesListFilter 筛选班级
             * teacherList 教师
             * supervisingTeacherList 督导教师
             * gradeList 年级列表
             * */
            schedulingClassesList: [],
            schedulingClassesListFilter: [],
            teacherList: [],
            supervisingTeacherList: [],
            gradeList: [],
            filterQueryList: {
                schoolId: '',
                productId: '',
                dayWeek: 'week',
                isTourClass: '',
                startTime: '',
                endTime: '',
                selectDay: '',
                classTeacherId: '',
                courseType: '1',
                scheduleClassId: '',
                scheduleGradeId: '', // 年级
            },
            /**
             * 根据年级查询课程时间 conditionList
             * 上课时间表,左侧渲染时间 courseList
             * */
            conditionList: {
                schoolId: '',
                productId: '',
                classId: '',
                isArrange: '',
                teacherId: '',
                gradeId: ''
            },
            courseList: [],
            weekList: [],
            dayList: [],

            /**
             * 弹窗相关
             * classPatrolList 巡课老师列表
             * tourDataList 已经巡课列表
             *
             * comPointer 详情/编辑组件数据集合
             * dayDetail/ 是否为单日详情
             * repeatClassDisabled 是否为编辑
             * courseScheduleDetail 新增/编辑数据合集
             * courseScheduleDetailSupervisingTeachersList 督导教师
             * */
            duplicate: '1',
            classPatrolList: [],
            tourDataList: [],
            showOption: true,
            classAndTeacher:{
                className:"",
                teacherName:''
            },
            comPointer: 'detail',
            dayDetail: false,
            repeatClassDisabled: false,
            courseScheduleDetail: {
                schoolId: '',
                productId: '',
                classArrangement: '',
                repeatType: '',
                repeatClass: '1', // 重复排课方式0不重复1每周重复2单周重复3双周重复(编辑)
                startScheduleTime: '',
                endScheduleTime: '',
                id: '', // 课程id
                weekAndNode:'',//周天+节日
                gradeClassName:'',//年级班级
                courseType: '', // 课表类型
                scheduleClassId: '', // 排课班级id（详情）
                classdata:'',//下拉列表班级数据
                scheduleClassName: '', // 排课班级名字（详情）
                scheduleClassName: '', // 排课班级名字（详情）
                subjectId: '', // 科目id（编辑）
                classTeacherId: '', // 上课教师id（编辑）
                supervisingTeacherId: '', // 督导教师id(编辑)
                scheduleDate: '',
                coursedate:'',
                scheduleTime: '', // 上课时间（详情）
                className: '', // 排课班级名称（详情）
                teacherName: '', // 上课教师（详情）
                subjectName: '', // 科目（详情）
                supervisingTeacherName: [],// 督导教师（详情）
                list: [{
                    week: '',
                    scheduleDate: '',
                    restTimeId: ''
                }],
                _time: '' // 详情显示
            },
            courseScheduleDetailRules: {
                repeatClass: [{ required: true, message: '请选择重复方式', trigger: 'change' }],
                coursedate: [{required: true, message: "请选择日期", trigger: ['blur', 'change'] }],
                subjectId: [
                    { required: true, message: '请选择科目', trigger: 'change' },
                ],
                classTeacherId: [
                    { required: true, message: '请选择上课教师', trigger: 'change' },
                ],
            },
            courseScheduleDetailSupervisingTeachersList: [],
            /**
             * 指针数据
             * scheduleClassIdPointer 年级指针，新增课程时
             *
             * */
             scheduleClassIdPointer: '',
            /**
             * tourDetailsData 巡课详情数据
             */
             tourDetailsData: {},
            /**
             * 预览PDF
             * 重复使用课程表列表组件，下载PDF状态
             *
             * */
            showDownloadPDFType: true,
            /**
             * 提前多久可以训课
             *
             * */
             advanceTime: 1000 * 60 * 30,
            tableJsonData: [],

            formData: {
                btnFormType: true,
                defaultclassid:'',
                data: [
                    {
                        placeholder: "选择班级/级联选择",
                        type: "cascader",
                        key: 'organId',
                        value: "",
                        organType: "",
                        list: [],
                        cascaderProps: {
                            label: "name",
                            multiple: false,
                            value: "id",
                            checkStrictly: false,
                            emitPath: false
                        },
                        filter(data) {
                            return data.organType !== '1';
                        },
                    }
                ],
            },
            selectChectArr:[],
            selectCourseIds:'',
            selectList_temporary:[],//清空后点击取消时用暂存的数组重新赋值给selectList
            checkedLength_temporary:0,//清空后点击取消时用暂存的长度重新赋值checkedLength
        }
    },
    computed: {
        ...mapState({
            schoolId: state => state.schoolId,
            isTourClass: state => state.isTourClass,
        }),
        emptyImg(){
            return require('@/assets/images/empty-patrol.png')
        },
    },
    created () {
        this.init()
    },
    mounted() {
        console.log('页面加载', this.selectedCourseList)
        this.$nextTick(() => {
            if (this.selectedCourseList) {
                this.selectedCourseList.forEach(item => {
                    item.child.forEach(i => {
                        this.changeCheck({item: i, type: i.type})
                    })
                })
            }
        })
    },
    methods: {
        /**
         * @Description: 初始化函数
         * @DoWhat: 初始化函数
         * @UseScenarios: 初始化页面
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-11 16:42:25
         */
        init () {
            this.initPageAssignment()
            this.initPageData()
        },
        /**
         * @Description: 初始化页面赋值
         * @DoWhat: 初始化页面赋值
         * @UseScenarios: 初始化页面赋值
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-11 16:44:16
         */
        initPageAssignment () {
            const _this = this;
            const courseScheduleTemp = new CourseScheduleTemp();

            this.queryList.schoolId = this.schoolId;
            this.queryList.productId = 'cloud_campus';

            this.filterQueryList.schoolId = this.schoolId;
            this.filterQueryList.productId = 'cloud_campus';
            // 排课星期请求赋值
            this.conditionList.schoolId = this.schoolId;
            this.conditionList.productId = 'cloud_campus';
            this.conditionList.isArrange = '1';

            // 新增课程/编辑
            this.courseScheduleDetail.schoolId = this.schoolId;
            this.courseScheduleDetail.productId = 'cloud_campus';
            this.courseScheduleDetail.classArrangement = '1';
            this.courseScheduleDetail.repeatType = '1';

            this.queryList.selectDay = courseScheduleTemp.getTimeDay(_this);
            console.log(this.queryList.selectDay,'this.queryList.selectDay')
        },
        /**
         * @Description: 初始化页面数据
         * @DoWhat: 初始化页面数据
         * @UseScenarios: 初始化页面数据
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-11 16:43:20
         */
        async initPageData () {
            this.loadingShow = true;
            this.getDataLoading = false;
            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            // // 班级列表（日/周 新增课程的时候，下拉框）
            await teachingResearchCourseScheduleTempModel.getSchoolClassList({'schoolId': this.schoolId }).then((res) => {
                if (res.data.code == '200') {
                    console.log(res.data.data.list,'[schedulingClassesList]')
                    this.schedulingClassesList = res.data.data.list;
                    this.getFindClassList(this.schedulingClassesList)
                    this.assignmentOperations()
                } else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
            })
            await teachingResearchCourseScheduleTempModel.getGradeClassListData({'schoolId': this.schoolId,jobScope:["1","2","3"]}).then((res) => {
                if (res.data.code == '200') {
                    const index = this.formData.data.findIndex((i) => i.type === 'cascader')
                    let localData = formatTreeData(res.data.data.list, "id", "parentOrg")
                    let newlocaldata =[];
                    for (let index = 0; index < localData.length; index++) {
                        const item = localData[index];
                        if(item.children){
                            newlocaldata.push(item)
                        }
                    }
                    this.formData.data[index].list =newlocaldata
                    let gradeClassList = res.data.data.list;
                    this.courseScheduleDetail.classdata=this.formData.data[index]
                    console.log("获取的下拉列表王园园",this.courseScheduleDetail.classdata)

                } else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
            })

            // 教师列表
            await teachingResearchCourseScheduleTempModel.getSchoolTeacherList({'schoolId': this.schoolId, "applyPerson": "1"}).then((res) => {
                if (res.data.code == '200') {
                    this.setDisabledOption(res.data.data);
                    console.log(res.data.data,'res.data.data 教师列表')
                    this.teacherList = res.data.data;
                    this.courseScheduleDetailSupervisingTeachersList = res.data.data;
                    console.log(this.teacherList,'this.teacherList教师数据')
                } else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
            })

            // 年级列表
            await teachingResearchCourseScheduleTempModel.getGradeOfTree({'schoolId': this.schoolId,
            "jobScope":[1,2,3]}).then((res) => {
                if (res.data.code == '200') {
                    this.gradeList = res.data.data;
                    console.log(res.data.data,'年级列表')
                } else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
            })

            // 请求上课时间
            await this.reClassTime()
        },

        //父组件删除第一个课程
        delSingleCourse(){
            if(this.selectList[0].child.length == 1){
                this.selectList.shift()
            }else{
                this.selectList[0].child.shift()
            }
            console.log('父组件删除第一个课程---已删除的数组',this.selectList)

            this.checkedLength = 0
            this.selectChectArr = []
            if(this.selectList.length>0){
                this.selectList.forEach(item => {
                    if(item.child.length>0){
                        this.checkedLength = this.checkedLength + item.child.length
                        this.selectChectArr.push(item)
                    }
                })
            }

            let tourObject = ''
            if (this.selectList.length != 0) {
                tourObject = this.selectList[0].child[0].teacherName + ' (' + this.selectList[0].child[0].className + ')' + ' ' + this.handleDate(this.selectList[0].child[0].scheduleTime)
            }
            this.$emit('setTourObject', {tourObject, selectList: this.selectList})
            this.keyArr = []
            this.selectList.forEach(s => {
                s.child.forEach(t => {
                    this.keyArr.push(t.id)
                })
            })
            this.weekList.forEach(item => {
                item.child.forEach((subItem) => {
                    if (subItem.id && this.keyArr.includes(subItem.id)) {
                        this.$set(subItem, 'checkState', true)
                    }else{
                        this.$set(subItem, 'checkState', false)
                    }
                })
            })
            this.selectList_temporary = this._.cloneDeep(this.selectList)
            this.checkedLength_temporary = this.checkedLength
        },


        /**
         * @Description: 删除某个课程
         * @Author: pyl
         * @Date: 2024-08-27 16:24:12
         * @param {*} val
         */
         delItem(val){
            this.checkedLength = 0
            this.selectList.forEach((item,index)=> {
                if(item.scheduleTime == val.scheduleTime){
                    let b = item.child.findIndex(a => a.id == val.id)
                    if(item.child.length == 1){
                        this.selectList.splice(index,1)
                    }else{
                        item.child.splice(b,1)
                    }
                }
            })
            this.selectChectArr = []
            if(this.selectList.length>0){
                this.selectList.forEach(item => {
                    if(item.child.length>0){
                        this.checkedLength = this.checkedLength + item.child.length
                        this.selectChectArr.push(...item.child)
                    }
                })
            }
            this.keyArr = this.keyArr.filter(item => item != val.id)
            this.weekList.forEach(item => {
                item.child.forEach((subItem) => {
                    if (subItem.id && this.keyArr.includes(subItem.id)) {
                        this.$set(subItem, 'checkState', true)
                    }else{
                        this.$set(subItem, 'checkState', false)
                    }
                })
            })
        },
        /**
         * @Description: 清空全部课表
         * @Author: mrz
         * @Date: 2024-08-31 19:08:34
         */
         delAll(){
            this.selectChectArr.forEach(item => {
                item.checkState = false
            })
            this.selectList = []
            this.selectChectArr = []
            this.checkedLength = 0
            // 点击清空时左侧课程表勾选状态
            this.weekList.forEach(item => {
                item.child.forEach((subItem) => {
                    this.$set(subItem, 'checkState', false)
                })
            })
            this.keyArr = []
         },
        /**
         * @Description: 关闭巡课范围弹窗
         * @Author: pyl
         * @Date: 2024-08-27 16:33:18
         */
        async handleCancel(bool) {
            this.$emit('handleTreeDialogClose')
            if (bool) {
                let tourObject = ''
                if (this.selectList.length != 0) {
                    tourObject = this.selectList[0].child[0].teacherName + ' (' + this.selectList[0].child[0].className + ')' + ' ' + this.handleDate(this.selectList[0].child[0].scheduleTime)
                }
                this.selectList_temporary = this._.cloneDeep(this.selectList)
                this.checkedLength_temporary = this.checkedLength
                this.$emit('setTourObject', {tourObject, selectList: this.selectList})
                console.log('点击确定时暂存的数据：',this.selectList_temporary,this.checkedLength_temporary,this.keyArr)
            } else {
                // 点击取消恢复到上次 确定时的数据
                console.log('课表弹框点击取消了--',this.selectList_temporary,this.checkedLength_temporary)
                this.selectList = this._.cloneDeep(this.selectList_temporary)
                this.checkedLength = this.checkedLength_temporary
                this.selectChectArr = []
                this.selectList_temporary.forEach(item => {
                    if(item.child && item.child.length>0){
                        item.child.forEach(subItem => {
                            this.selectChectArr.push(subItem)
                        })
                    }
                })
                this.keyArr = []
                this.selectList_temporary.forEach(s => {
                    s.child.forEach(t => {
                        this.keyArr.push(t.id)
                    })
                })
                console.log('this.selectList取消时------',this.selectList,this.selectChectArr)
                this.weekList.forEach(item => {
                    item.child.forEach((subItem) => {
                        if (subItem.id && this.keyArr.includes(subItem.id)) {
                            this.$set(subItem, 'checkState', true)
                        }else{
                            this.$set(subItem, 'checkState', false)
                        }
                    })
                })
                // this.delAll()
            }
        },
        /**
         * @Description:处理日期
         * @Author: mrz
         * @Date: 2024-08-26 10:25:53
         */
         handleDate(val){
            const days = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
            const date = new Date(val)
            return days[date.getDay()]  + ' (' + val + ')'

         },
        /**
         * @Description: 获取上课时间
         * @DoWhat: 获取上课时间
         * @UseScenarios: 获取上课时间
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-15 10:43:19
         */
         async reClassTime () {
            console.log(this.queryList,'请求课程表')
            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            this.conditionList.classId = this.queryList.scheduleClassId;
            this.conditionList.teacherId = this.queryList.classTeacherId;
            this.courseScheduleDetail.classTeacherId = this.queryList.classTeacherId;
            console.log(this.conditionList.gradeId,'请求课程表+++ this.conditionList')
            await teachingResearchCourseScheduleTempModel.getClassWeek(this.conditionList).then((res) => {
                this.courseList = [];
                if (res.data.code == '200') {
                    console.log(res.data,'上课星期数据')
                    res.data.data.forEach((item) => {
                        let obj = {
                            id: item.id,
                            label: `${item.name}`,
                            time: `${item.startTime}-${item.endTime}`,
                            starTime: item.startTime,
                            gradeId: item.gradeId,
                            gradeName:item.gradeName
                        };
                        this.courseList.push(obj)
                    })

                    this.changeQuery()
                } else if (res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
            this.classnameandteacher()
         },
         async classnameandteacher(){
            // 获取班级和老师
            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            let  postdic;
            if(this.queryList.courseType ==='1'){
                postdic={
                classId: this.queryList.scheduleClassId
                }
            }else if(this.queryList.courseType ==='2'){
                postdic={
                teacherId: this.queryList.classTeacherId
                }
            }else if(this.queryList.courseType ==='3'){
                postdic={
                classId: this.queryList.scheduleGradeId
                }
            }
            await teachingResearchCourseScheduleTempModel.getClassNameTeacherData(postdic).then((res) => {
                let teacherNames = res.data.data.teacherNames

                let teacherNameStr;
                if(this.queryList.courseType==='1'){
                    if(teacherNames.length>0){
                    teacherNameStr = teacherNames[0];
                    for (var i = 1; i < teacherNames.length; i++) {
                        teacherNameStr = teacherNameStr + '、' + teacherNames[i]
                    }
                   }else{
                    teacherNameStr='-'
                   }
                }else{
                    teacherNameStr=res.data.data.teacherName
                }

                this.classAndTeacher={
                    className:res.data.data.className,
                    teacherName:teacherNameStr
                }
                if (this.queryList.courseType == '1') {
                    this.courseScheduleDetail.gradeClassName = res.data.className
                }
            })
         },
        /**
         * @Description: 写入禁用项
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2022-11-28 10:09:29
         */
         setDisabledOption (list) {
            list.forEach((item) => {
                if (item.key !== 'teacher') {
                    this.$set(item, 'disabled', true)
                } else {
                    this.$set(item, 'disabled', false)
                }

                if (item.children && item.children.length > 0) {
                    this.setDisabledOption (item.children)
                }
            })
         },
        /**
         * @Description: 获取页面数据
         * @DoWhat: 获取页面数据
         * @UseScenarios: 获取页面数据
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-11 16:45:09
         */
        getList() {
            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            teachingResearchCourseScheduleTempModel.getCourseScheduleList().then((res) => {
                console.log(res, 'res')
            })
        },
        changeQuery() {
            const _this = this;
            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            const courseScheduleTemp = new CourseScheduleTemp();

            this.getDataLoading = true;
            courseScheduleTemp.sortingQueryList(this.queryList, this.filterQueryList, _this)
            teachingResearchCourseScheduleTempModel.getSchoolCourseList(this.filterQueryList).then((res) => {
                if (res.data.code == '200') {
                    console.log(res.data.data,'列表数据',_this.queryList.dayWeek)
                    switch (_this.queryList.dayWeek) {
                        case 'day':
                            _this.dayList = [{label: '', child: []}];
                            courseScheduleTemp.getDayList(_this.filterQueryList, res.data.data, _this.dayList, _this.courseList, _this);
                            break;
                        case 'week':
                            _this.weekList = [];
                            courseScheduleTemp.getWeekList(_this.filterQueryList, res.data.data, _this.weekList, _this.courseList, _this);
                            // keyArr
                            _this.weekList.forEach((item) => {
                                item.child.forEach(subItem => {
                                    if (this.keyArr.includes(subItem.id)) {
                                        this.$set(subItem, 'checkState', true);
                                    }

                                })
                            })
                            console.log(_this.weekList,'_this.weekList===---===')
                            break;
                        default:
                            break;
                    }
                } else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
                this.loadingShow = false;
                this.getDataLoading = false;

            })
        },
        /**
         * @Description: 选择课程类型
         * @DoWhat: 选择课程类型
         * @UseScenarios: 头部筛选
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-04 10:14:38
         */
        handleChangeCourseType (e) {
            this.queryList.classTeacherId = ''
            this.queryList.scheduleGradeId = ''
            this.conditionList.gradeId = ''
            this.conditionList.teacherId = ''
            this.queryList.courseType = e;
            this.courseScheduleDetail.courseType = e;
            this.loding=false;

            console.log(this.queryList.courseType,'this.queryList.courseType-----------------------------');
            switch (this.queryList.courseType) {
                case '1':
                this.conditionList.gradeId = ''; // 清除年级赋值
                this.queryList.scheduleGradeId = ''; // 清除年级ID
                this.queryList.classTeacherId = '';  // 清除教师ID
                this.reClassTime ()
                    break;
                case '2':
                this.conditionList.gradeId = ''; // 清除年级赋值
                this.queryList.scheduleGradeId = ''; // 清除年级ID
                this.queryList.classTeacherId = '';  // 清除教师ID
                this.reClassTime ()

                    break;
                case '3':
                this.conditionList.teacherId = ''; // 清除年级赋值
                this.conditionList.classId ='';
                this.queryList.scheduleGradeId = ''; // 清除年级ID
                this.queryList.classTeacherId = '';  // 清除教师ID

                this.$nextTick(function(){
                    console.log(this.queryList.scheduleGradeId,'this.queryList.scheduleGradeId')
                    this.handlerGradeChange() // 根据年级获取班级，渲染左侧
                })
                    break;
                default:
                    break;
            }
            setTimeout(() => {
                this.loding = true
            }, 500);
            // this.classnameandteacher()
        },
        /**
         * @Description: 选择日期
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 王园园
         * @Date: 2023-07-21 10:45:44
         */
        changeCascader(data){
            console.log(data,data.ref.getCheckedNodes(),'===========');
            if(data.data.cascaderProps.multiple){
                let nodes =data.ref.getCheckedNodes()
                let nodeidstr=''
                if(nodes.length>0){
                    for (var i = 0; i < nodes.length; i++) {
                        let nodeitem = nodes[i]

                        if (nodeitem.children.length==0) {
                            nodeidstr = nodeidstr + ',' + nodes[i].value
                        }

                    }
                }
                nodeidstr = nodeidstr.slice(1);
                this.courseScheduleDetail.classTeacherId = this.queryList.classTeacherId;

            }else if (data.ref.getCheckedNodes()[0]) {
                let node;
                node = data.ref.getCheckedNodes()[0].data;
                this.queryList.scheduleClassId=node.id;
                this.courseScheduleDetail.classTeacherId = this.queryList.classTeacherId;
                this.reClassTime ()
                this.classnameandteacher()
            } else {
                this.queryList.scheduleClassId = ''
            }
            console.log("级联菜单id",this.queryList.scheduleClassId)
        },
        /**
         * @Description: 批量删除
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 王园园
         * changeCheck复选方法
         * onceDelCourse 单个删除
         * @Date: 2023-07-20 9:45:44
         */
         changeCheck(data){
            console.log('couse拿到的数据:',data)
            console.log('课程表被点击---',this.selectChectArr)
            this.selectCourseIds=""
            if(data.item.checkState){
                data.item['type'] = data.type
                this.selectChectArr.push(data.item)
            }else{
                for (let index = 0; index < this.selectChectArr.length; index++) {
                    const foritem = this.selectChectArr[index];
                    if(foritem.id == data.item.id){
                        this.selectChectArr.splice(index, 1)
                        break;
                    }
                }
            }
           if(this.selectChectArr.length>0){
            this.selectCourseIds = this.selectChectArr[0].id;
            for (var i = 1; i < this.selectChectArr.length; i++) {
              this.selectCourseIds = this.selectCourseIds + ',' + this.selectChectArr[i].id
            }
           }
           this.selectChectArr = this.uniqueByJSON(this.selectChectArr)
           console.log('去重后的-----',this.selectChectArr)
           this.handleTarget(this.selectChectArr)
         },
        /**
         * @Description: 包含对象的数组中去重
         * @Author: pyl
         * @Date: 2024-09-01 17:47:20
         * @param {*} arr
         */
        uniqueByJSON(arr) {
            let cleaned = [];
            const stringified = new Set();

            for (const item of arr) {
                const stringifiedItem = JSON.stringify(item);
                if (!stringified.has(stringifiedItem)) {
                    cleaned.push(item);
                    stringified.add(stringifiedItem);
                }
            }
            // 根据id去重教师课表和班级课表重复数据
            const uniqueArray = Object.values(cleaned.reduce((acc, curr) => {
                acc[curr.id] = curr;
                return acc;
            }, {}))
            console.log('去重了吗',uniqueArray)
            return uniqueArray;
        },
        /**
         * @Description: 选择数据格式化处理
         * @Author: pyl
         * @Date: 2024-08-27 16:06:13
         * @param {*} list
         */
        handleTarget(list){
            // 已选课程数量
            this.checkedLength = 0
            this.selectList = []
            const target = {}
            list.forEach((item,index) => {
                let {scheduleTime} = item
                if(!target[scheduleTime]){
                    target[scheduleTime] = {
                        scheduleTime,
                        child:[]
                    }
                }
                target[scheduleTime].child.push(item)
            })
            this.selectList = Object.values(target)
            this.selectList.sort((a,b) => a.scheduleTime.localeCompare(b.scheduleTime))
            if(this.selectList.length>0){
                this.selectList.forEach(item => {
                    if(item.child.length>0){
                        this.checkedLength = this.checkedLength + item.child.length
                        if (item.child[0].type == '2') {
                            item.child.sort((a,b) => (a._time.split("-")[0].split(":")[0]*3600 + a._time.split("-")[0].split(":")[1]*60) -( b._time.split("-")[0].split(":")[0]*3600 + b._time.split("-")[0].split(":")[1]*60))
                        } else {
                            item.child.sort((a,b) => (a.time.split("-")[0].split(":")[0]*3600 + a.time.split("-")[0].split(":")[1]*60) -( b.time.split("-")[0].split(":")[0]*3600 + b.time.split("-")[0].split(":")[1]*60))
                        }
                    }
                })
            }
            this.keyArr = []
            console.log('this.selectList已选数据:',this.selectList)
            this.selectList.forEach(s => {
                s.child.forEach(t => {
                    this.keyArr.push(t.id)
                })
            })
            // this.keyArr = Object.keys(this.selectList)
         },

         /**
          * @Description:
          * @DoWhat:
          * @UseScenarios:
          * @Attention:
          * @Author:
          * @Date: 2022-11-16 21:54:56
          */
          dataAddCourse (detailDaydata) {
            console.log(detailDaydata,'日 新增课程')


            let data = detailDaydata.data
            let subject = detailDaydata.daylist.child[detailDaydata.idx]
            if(this.queryList.courseType === 2){
                this.courseScheduleDetail.weekAndNode  =detailDaydata.daylist.label.substr(-2) +" | "+'作息'
            }else{
                this.courseScheduleDetail.weekAndNode  =detailDaydata.daylist.label.substr(-2) +" | "+subject._uuName
            }
            this.courseScheduleDetail._time = subject._time

            this.courseScheduleDetail.id = ''; //data._uuid
            this.courseScheduleDetail.classTeacherId = this.queryList.classTeacherId;
            this.courseScheduleDetail.scheduleTime = data._day;

            this.courseScheduleDetail.scheduleDate = data._day;
            this.courseScheduleDetail.startScheduleTime = data._day;
            this.courseScheduleDetail.endScheduleTime = data._day;

            this.getClassName(this.schedulingClassesList) // 获取班级名称

            this.courseScheduleDetail.list[0].scheduleDate = data._day;
            this.courseScheduleDetail.list[0].restTimeId = data._uuid;
            this.courseScheduleDetail.list[0].week = data._week;

            // 根据左侧课程表 显示选择的班级
            this.getNewClassDropDown(this.schedulingClassesList, data._gradeId)
            this.schedulingClassesList.forEach((item) => {
                if (data._gradeId == item.id) {
                    this.schedulingClassesListFilter = [item];
                }
            })
            console.log(this.schedulingClassesListFilter,'班级列表')
            if (this.queryList.courseType == '1') {
                this.courseScheduleDetail.scheduleClassId = this.queryList.scheduleClassId;
            } else {
                this.courseScheduleDetail.scheduleClassId = this.getScheduleClassName(this.schedulingClassesListFilter)
            }
            if (this.queryList.courseType == '3') {
                this.courseScheduleDetail._timer = data.data.time;
            }


            console.log(this.courseScheduleDetail,'周--添加课程')
            this.comPointer = '';
            this.dayDetail = false;
            this.repeatClassDisabled = false;
          },
         /**
          * @Description: 获取班级列表
          * @DoWhat: 获取班级列表
          * @UseScenarios:
          * @Attention: 无
          * @Author: 武东超
          * @Date: 2023-02-14 18:36:39
          * @param {*} list
          */
          getNewClassDropDown (list, gradeId) {
            console.log(list,'list')
            list.forEach((item) => {
                if (gradeId == item.id) {
                    this.schedulingClassesListFilter = [item];
                }

                if (item.child && item.child.length > 0) {
                    this.getNewClassDropDown (item.child, gradeId)
                }
            })
          },
         getScheduleClassName (list) {
            let str = '';
            for (let i = 0; i < list.length; i++) {
                console.log(list[i],'list[i].organType')
                if (list[i].organType == '4') {
                    str = list[i].id;
                    return str;
                }
                if (list[i].child && list[i].child.length > 0) {
                    return this.getScheduleClassName (list[i].child, str)
                }
            }

         },
         getClassName (list) {
            list.forEach((item) => {
                if (this.scheduleClassIdPointer == item.id) {
                    this.courseScheduleDetail.className = item.name;
                }
                if (item.child.length > 0) {
                    return this.getClassName(item.child)
                }
            })
         },

        /**
         * @Description: 获取科目
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2023-02-02 09:27:57
         */
         getSubjectName (list, courseScheduleDetail) {
            list.forEach((item) => {
                if (courseScheduleDetail.subjectId == item.id) {
                    courseScheduleDetail.subjectName = item.name;
                }
            })
         },
        /**
         * @Description: 获取班级名称
         * @DoWhat: 获取班级名称
         * @UseScenarios: 获取班级名称
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-24 16:38:25
         */
         getFindClassList (list) {
            for(var i = 0; i < list.length; i++) {
                if (list[i].child.length > 0) {
                    this.getFindClassList (list[i].child);
                    break;
                } else if (list[i]){
                    this.queryList.scheduleClassId = list[i].id;
                    console.log(list[i].name,'list[i]---00')
                    break;
                }
            }
         },
         getFindClassList1 (list) {
            for(var i = 0; i < list.length; i++) {
                if (list[i].child.length > 0) {
                    this.getFindClassList (list[i].child);
                    break;
                } else if (list[i]){
                    this.queryList.scheduleClassId = list[i].id;
                    this.courseScheduleDetail.scheduleClassId = list[i].id;
                    break;
                }
            }
         },
         getFindClassList2 (list) {
            for(var i = 0; i < list.length; i++) {
                if (list[i].child.length > 0) {
                    this.getFindClassList (list[i].child);
                    break;
                } else if (list[i]){
                    this.courseScheduleDetail.scheduleClassId = list[i].id;
                    break;
                }
            }
         },
        /**
         * @Description: 获取教师名称
         * @DoWhat: 获取教师名称
         * @UseScenarios: 获取教师名称
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-24 17:21:26
         */
        getFindTeacher (list) {
            for(var i = 0; i < list.length; i++) {
                if (list[i].children.length > 0) {
                    this.getFindTeacher (list[i].children);
                    break;
                } else if (list[i]){
                    this.queryList.classTeacherId = list[i].id;
                    this.courseScheduleDetail.classTeacherId = list[i].id;
                    break;
                }
            }
        },
        getFindTeacher1 (list) {
            for(var i = 0; i < list.length; i++) {
                if (list[i].children.length > 0) {
                    this.getFindTeacher (list[i].children);
                    break;
                } else if (list[i]){
                    this.courseScheduleDetail.classTeacherId = list[i].id;
                    break;
                }
            }
        },
        /**
         * @Description: 获取年级第一个数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-02-01 10:30:37
         */
         getFindGrade (list) {
            for(var i = 0; i < list.length; i++) {
                if (list[i].child.length > 0) {
                    this.getFindGrade (list[i].child);
                    break;
                } else if (list[i].organType == '3'){
                    this.queryList.scheduleGradeId = list[i].id;
                    break;
                }
            }
         },
        /**
         * @Description: 集中处理函数，某些值改变则改变赋值操作
         * @DoWhat: 集中处理函数，某些值改变则改变赋值操作
         * @UseScenarios: 集中处理函数，某些值改变则改变赋值操作
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-16 09:21:39
         */
         assignmentOperations () {
            this.scheduleClassIdPointer = this.queryList.scheduleClassId;
            console.log(this.scheduleClassIdPointer,'年级指针')
         },
        /**
         * @Description: 选择班级变化，请求新的作息时间表
         * @DoWhat: 选择班级变化
         * @UseScenarios: 选择班级变化
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-16 09:31:31
         */
         handlerScheduleClassIdChange () {
            console.log('年级变化', this.queryList.scheduleClassId)
            this.assignmentOperations()
            this.reClassTime('loading');
         },
        /**
         * @Description: 更改老师
         * @DoWhat: 更改老师
         * @UseScenarios: 更改老师
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-25 15:40:17
         */
         handlerTeacherChange () {
            this.reClassTime ()
         },
        /**
         * @Description: 更改年级
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-01-31 19:06:25
         */
         handlerGradeChange () {
            this.courseList = []
            console.log(this.queryList.scheduleGradeId,'年级')
            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            teachingResearchCourseScheduleTempModel.getSpliceOrganList({'schoolId': this.schoolId, 'id': this.queryList.scheduleGradeId,'jobScope':[1,2,3]}).then((res) => {
                if (res.data.code == '200') {
                    res.data.data.forEach((item) => {
                        let obj = {
                            id: item.id,
                            label: `${item.name}`,
                        };
                        this.courseList.push(obj)
                    })
                    console.log(res.data.data,'根据年级获取班级')
                    this.getClassTime();
                } else if (res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
         },
         getClassTime () {
            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            this.conditionList.gradeId = this.queryList.scheduleGradeId;
            console.log(this.queryList, this.conditionList,'年级课程表获取表头')
            teachingResearchCourseScheduleTempModel.getClassWeek(this.conditionList).then((res) => {
                this.weekList = [];
                if (res.data.code == '200') {
                    console.log(res.data.data,'年级上课星期数据')
                    res.data.data.forEach((item) => {
                        let obj = {
                            _id: item.id,
                            label: `${item.name}  ${item.startTime}-${item.endTime}`,
                            time: `${item.startTime}-${item.endTime}`,
                            starTime: item.startTime,
                            gradeId: item.gradeId,
                            endTime: `${item.endTime}`
                        };
                        this.weekList.push(obj)
                    })
                    console.log(this.weekList,'this.weekList')
                    this.gradeTableList()
                } else if (res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
            this.classnameandteacher()
         },
        /**
         * @Description: 年级table列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-02-01 11:44:30
         */
         gradeTableList () {
            this.getDataLoading = true;
            this.queryList.dayWeek = 'week'; // 表头展示多个

            this.filterQueryList.selectDay = this.queryList.selectDay;
            this.filterQueryList.courseType = '3';
            this.filterQueryList.startTime = this.queryList.selectDay;
            this.filterQueryList.endTime = this.queryList.selectDay;
            this.filterQueryList.scheduleGradeId = this.queryList.scheduleGradeId;
            console.log(this.queryList, this.filterQueryList,'获取年级table')
            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            teachingResearchCourseScheduleTempModel.getSchoolCourseList(this.filterQueryList).then((res) => {
                if (res.data.code == '200') {
                    console.log(this.weekList, '上边', this.courseList,'左侧3333333333')


                      let str = Object.prototype.toString,
                          type = '[object Object]',
                          len = this.weekList.length,
                          data = res.data.data;

                     for (let i = 0; i < len; i++) {
                        console.log('进来来说')
                        this.$set(this.weekList[i], 'id', i);
                        this.$set(this.weekList[i], 'data', this.queryList.selectDay)
                        this.$set(this.weekList[i], 'child', []);
                     }

                     this.weekList.forEach((item, index) => {
                        this.courseList.forEach((subItem, subIndex) => {
                            let obj = {
                                _uuid: subItem.id,
                                _uuName: subItem.label,
                                _time: this.filterQueryList.selectDay,
                                _day: item.data,
                                _class: subItem.id,
                                _deadline: item.data +' '+ item.starTime,
                                _timestamp: new Date(item.data +' '+ item.starTime).getTime(),
                                _gradeId: item.gradeId,
                                operateShow:false
                            };
                            this.$set(obj, 'checkState', false);
                            item.child.push(obj);
                        });
                    })
                    if(str.call(data) !== type) {
                        this.weekList.forEach((item, index) => {
                            item.child.forEach((pItem, pIndex) => {
                                data.forEach((subItem) => {
                                    if (item._id === subItem.restTimeId && pItem._uuid == subItem.scheduleClassId) {
                                        item.child.splice(pIndex, 1, Object.assign(subItem ,pItem))
                                    }
                                })
                            })
                        })
                    }

                    this.weekList.forEach(item => {
                        item.child.forEach((subItem) => {
                            if (subItem.id && this.keyArr.includes(subItem.id)) {
                                this.$set(subItem, 'checkState', true)
                            }
                        })
                    })

                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }

                this.getDataLoading = false;
            })
         },
    },
    watch: {
        'queryList.courseType': {
            handler () {
                /**
                 * 1. 课表类型变化时，新增课程表数据变化
                 * 2. 请求新的课程表接口
                 * */
                this.courseScheduleDetail.courseType = this.queryList.courseType;
                if (this.queryList.courseType == '3') {
                    return;
                } else {
                    this.changeQuery()
                }

            },
            deep: true
        },
        'queryList.classTeacherId': {
            handler () {
                this.changeQuery()
            },
            deep: true
        },
        'queryList.selectDay': {
            handler () {
                if (this.queryList.courseType == '3') {
                    console.log('3', this.queryList.selectDay)
                    this.gradeTableList ()
                    // this.changeQuery()
                } else {
                    this.changeQuery()
                }

            },
            deep: true
        },
        'queryList.isTourClass': {
            handler () {
                if (this.queryList.courseType == '3') {
                    console.log('3', this.queryList.selectDay)
                    this.gradeTableList ()
                } else {
                    this.changeQuery()
                }
            },
            deep: true
        },
    }
}
</script>
<style lang="scss" scoped>
    .empty-area {
        width: 100%;
        height: calc(100vh - 234px);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        text-align: center;
        div {
            width: 120px;
        }
    }
    .teaching-research-course-schedule-temp {
        width: 100%;
        box-sizing: border-box;
        .content-main {
            background-color: #fff;
            box-sizing: border-box;
            overflow:auto;
            width: calc(80vw - 415px);
        }
    }
    ::v-deep {
        .el-dialog{
            .el-form-item--small .el-form-item__content{
                line-height: 18px;
                display: flex;
                align-items: center;
            }
        }
    }
    .parting-line {
        width: 0px;
        border: 1px dashed #D8D8D8;
        margin-left: 10px;
        margin-right: 10px;
    }
    .select-wrap {
        width: 310px;
        .title {
            display: flex;
            align-items: center;
            .line {
                width: 4px;
                height: 16px;
                background: #3C7FFF;
                border-radius: 2px;
                margin-right: 5px;
            }
            .text {
                font-family: Microsoft YaHei;
                font-weight: bold;
                font-size: 16px;
                color: #2B2F33;
            }
        }
        .statistics {
            margin-top: 13px;
            .clear {
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 14px;
                color: #3C7FFF;
                margin-left: 10px;
                cursor: pointer;
            }
        }
        .course-taget{
            height: calc(100vh - 234px);
            overflow-y: auto;
        }
    }
</style>
